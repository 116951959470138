/* roboto-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v30-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v30-latin_latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v30-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v30-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v30-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v30-latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/roboto-v30-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v30-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v30-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v30-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
  margin: 0rem 0rem 5rem 0rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;

  background-color: #f6f7f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}
